import * as React from "react"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"
import ReduxWrapper from "../redux/reduxWrapper";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container text-center my-5">
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>

  </Layout>
)

const WrappedPage = (props) => (
    <ReduxWrapper element={<NotFoundPage {...props} />} />
)

export default WrappedPage
